'use strict';

var base = require('../product/base');

const appendToUrl = require('../util/appendToUrl');
const cartHelpers = require('./cartHelpers');

const submitCart = () => {
    $('body').on('click', '.checkout-btn', function (e) {
        e.preventDefault();

        $('body').trigger('checkout:startFromCart');

        // Redirects to checkout
        window.location.href = this.href; // Checkout-Login
    });
};

/**
 * Removes duplicate callout messages
 */
function removeDuplicateCalloutMessages() {
    let messages = [];
    let controls = [];
    let classes = [];

    $('.cart__callouts div').each(function () {
        let message = $(this).html();
        let classList = $(this).attr('class').split(/\s+/);
        let control = $(this).text().trim();

        if (message && message !== '' && control && control !== '' && !controls.includes(control)) {
            messages.push(message);
            controls.push(control);

            if (classList.filter(x => x !== 'd-none').length) {
                classes.push(classList);
            }
        }
    });

    $('.cart__callouts div').remove();
    messages.forEach(function (message, index) {
        $('.cart__callouts').append(`<div class="${classes[index] ? classes[index].join(' ') : ''}">${message}</div>`);
    });
}

const removeVoucher = () => {
    $('body').on('click', '.remove-voucher', function (e) {
        e.preventDefault();

        const voucherCode = $(this).data('voucher-code');
        let url = $(this).data('remove-voucher-url'); // Cart-RemoveVoucher
        url = appendToUrl(url, {
            voucherCode: voucherCode
        });

        $.ajax({
            url: url,
            type: 'post',
            success: function (data) {
                cartHelpers.updateCartTotals(data);
                removeDuplicateCalloutMessages();
                $(`.voucher-${voucherCode}`).fadeOut(function () {
                    $(this).remove();
                });
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                $('.bm-coupons').empty().append(data.totals.BMCouponsHtml);
            }
        });
    });
};

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
        + 'fade show" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

const cartNotification = () => {
    var container = $('.js-cart-notification');
    var close = container.find('button.close');

    close.on('click', function () {
        container.html('');
    });
};

const handleQuantityChange = () => {
    $('body').on('change', '.input-quantity', function () {
        const preSelectQty = $(this).data('pre-select-qty');
        let quantity = parseInt($(this).val(), 10);
        const max = parseInt($(this).attr('max'), 10);
        const min = parseInt($(this).attr('min'), 10);

        if (quantity > max) {
            quantity = max;
        } else if (quantity < min) {
            quantity = min;
        }

        if (quantity !== preSelectQty) {
            var productID = $(this).data('pid');
            var url = $(this).data('action');
            var uuid = $(this).data('uuid');
            var $productContainer = $(this).parents('.product-info');

            var urlParams = {
                pid: productID,
                quantity: quantity,
                uuid: uuid
            };
            url = appendToUrl(url, urlParams); // Cart-UpdateQuantity

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                context: this,
                dataType: 'json',
                success: function (data) {
                    if (parseInt(preSelectQty, 10) > quantity) {
                        var basketData = {
                            basket: data,
                            lineItemContainer: $productContainer,
                            quantityChange: true
                        };

                        $(document).trigger('cart:afterRemoveProduct', basketData);
                    } else if (parseInt(preSelectQty, 10) < quantity) {
                        var cartData = {
                            cart: data,
                            $productContainer: $productContainer
                        };

                        $('body').trigger('product:afterAddToCart', cartData);
                    }

                    location.reload();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $(this).val(parseInt(preSelectQty, 10));
                        $.spinner().stop();
                    }
                }
            });
        } else {
            // Reset wrong quantity of input to a correct value
            $(this).val(preSelectQty);
        }
    });
};

const productQuantitySelectorInit = () => {
    require('../product/quantity').init();
    handleQuantityChange();
};

const bonusProductListener = () => {
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $.ajax({
            url: $(this).data('url'), // Cart-EditBonusProduct
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

const imageZoom = () => {
    $('.js-summary-zoom').magnificPopup({
        type: 'image'
    });
};

const updateSizeAttributeLineItem = () => {
    // change tis func to a listener on the dropdown clicks
    $('body').on('click', '[data-attr="size"] a', function (e) {
        e.preventDefault();
        const $this = $(this);
        const $parent = $this.parents('.product-info');
        const url = $('.js-product-attributes').data('url'); // Cart-UpdateSize
        // const pid = $parent.data('pid');
        const uuid = $parent.data('uuid');
        const newPid = $this.data('product-id');

        var form = {
            uuid: uuid,
            pid: newPid
        };

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            data: form,
            success: function () {
                location.reload();
            }
        });
    });
};

module.exports = function () {
    cartNotification();
    productQuantitySelectorInit();
    updateSizeAttributeLineItem();
    removeDuplicateCalloutMessages();
    bonusProductListener();
    imageZoom();
    submitCart();
    removeVoucher();

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');

        var $productToRemoveSpan = $('.product-to-remove');
        $productToRemoveSpan.empty().append(productName);

        const $lineItemContainer = $('.uuid-' + uuid);
        const $removeProductModal = $('#removeProductModal');
        const $acceptButton = $removeProductModal.find('.cart-delete-confirmation-btn');
        const $moveToWishlistButton = $removeProductModal.find('.cart-move-to-wishlist-btn');
        const $wishlistTile = $lineItemContainer.find('.js-wishlistTile');

        const isInWishlist = $wishlistTile.hasClass('active');
        $moveToWishlistButton.toggleClass('d-none', isInWishlist);

        const removeProductCallback = (shouldMoveToWishlist) => {
            $removeProductModal.modal('hide');

            if (shouldMoveToWishlist) {
                $wishlistTile.trigger('click');
            }

            const urlParams = {
                pid: productID,
                uuid: uuid
            };
            actionUrl = appendToUrl(actionUrl, urlParams);

            $('body > .modal-backdrop').remove();

            $.spinner().start();
            $.ajax({
                url: actionUrl, // Cart-RemoveProductLineItem
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    cartHelpers.redrawCartItems(data, uuid);
                    if (data.abandonedCartMessage) {
                        $('.minicart').trigger('abandonedcartmessage:update', data.abandonedCartMessage);
                    }

                    data.lineItemContainer = $lineItemContainer;
                    $(document).trigger('cart:afterRemoveProduct', data);
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        };

        $acceptButton.unbind('click').on('click', () => {
            removeProductCallback(false);
        });

        if (!isInWishlist) {
            $moveToWishlistButton.unbind('click').on('click', () => {
                removeProductCallback(true);
            });
        }
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    $('.bm-coupons').empty().append(data.totals.BMCouponsHtml);
                    cartHelpers.updateCartTotals(data);
                    cartHelpers.updateCalloutMessages(data.approachingDiscounts, data.items);
                    cartHelpers.validateBasket(data);
                    cartHelpers.updateCartProductCardLabel(data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();
        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var url = $(this).data('action');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).fadeOut(function () {
                    $(this).remove();
                });
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                $('.bm-coupons').empty().append(data.totals.BMCouponsHtml);
                cartHelpers.updateCartTotals(data);
                cartHelpers.updateCalloutMessages(data.approachingDiscounts, data.items);
                cartHelpers.validateBasket(data);
                cartHelpers.updateCartProductCardLabel(data);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    base.selectAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    // base.showMoreBonusProducts();
    base.addBonusProductsToCart();
};
