'use strict';

const select = require('../components/select');
const MagnificPopup = require('./magnificPopup');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;
    if ($('.product-set-detail').length || $('.product-set').length) {
        const $target = $el.parents('.product-detail').first();
        pid = $target.find('.size-button.size-value.selected').data('product-id');
    } else {
        pid = $el.parents(('.product-detail:not(".bundle-item")')).data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processSwatchValues(attr, $productContainer) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="'
            + attrValue.value + '"]');
        var $swatchAnchor = $attrValue.parent();

        if (attrValue.selected) {
            $productContainer.find('.attribute-selected-swatch-value').text(attrValue.displayValue);
            $attrValue.addClass('selected');
        } else {
            $attrValue.removeClass('selected');
        }

        if (attrValue.url && !attrValue.showOutOfStock) {
            $swatchAnchor.attr('href', attrValue.url);
        } else {
            $swatchAnchor.removeAttr('href');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        if (attrValue.selectable && !attrValue.showOutOfStock) {
            $attrValue.addClass('selectable');
            $attrValue.attr('title', '');
            $attrValue.tooltip('dispose');
        } else {
            $attrValue.addClass('unselectable');
            $attrValue.attr('title', $('[data-tooltip]').attr('data-tooltip'));
            $attrValue.tooltip();
        }
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('href', attrValue.url).data('product-id', attrValue.productID)
            .removeAttr('disabled')
            .removeClass('selected')
            .removeClass('disabled');

        if (!attrValue.selectable || attrValue.showOutOfStock) {
            $attrValue.attr('disabled', true);
            $attrValue.addClass('disabled');
        }

        if (attrValue.selected) {
            $attrValue.attr('href', attr.resetUrl);
            $attrValue.addClass('selected');
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAttrs(attrs, $productContainer) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<div>' + response.resources.info_selectforstock + '</div>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<div>' + message + '</div>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for promotions section
 *
 * @param {array} promotions - list of promotions
 * @return {string} - Compiled HTML
 */
function getPromotionsHtml(promotions) {
    if (!promotions) {
        return '';
    }

    var html = '';

    promotions.forEach(function (promotion) {
        html += '<div class="callout" title="' + promotion.details + '">' + promotion.calloutMsg
            + '</div>';
    });

    return html;
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
    options.forEach(function (option) {
        var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id
            + '"]');
        option.values.forEach(function (value) {
            var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
            valueEl.val(value.url);
        });
    });
}

/**
 * Updates the size information on the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateSizeAdvice(response, $productContainer) {
    const $adviceContainer = $productContainer.find('.safesize-general-advice');
    const $override = $adviceContainer.find('.js-safesize-general-advice-override');
    const $larger = $adviceContainer.find('.js-safesize-general-advice-larger');
    const $smaller = $adviceContainer.find('.js-safesize-general-advice-smaller');
    const $equal = $adviceContainer.find('.js-safesize-general-advice-equal');
    const rounding = $adviceContainer.data('safesize-rounding');
    const trueSize = response && response.product && response.product.trueSize ? Number(response.product.trueSize) : null;
    const selectedSize = response && response.product && response.product.tracking && response.product.tracking.size ? Number(response.product.tracking.size) : null;
    const isLarger = trueSize - rounding > selectedSize;
    const isSmaller = trueSize + rounding < selectedSize;
    const isEqual = !isLarger && !isSmaller;

    if (response.product.advisedSize) {
        $adviceContainer.removeClass('d-none');
        $override.text(response.product.advisedSize);
        $larger.addClass('d-none');
        $smaller.addClass('d-none');
        $equal.addClass('d-none');
        $override.removeClass('d-none');
    } else if (trueSize) {
        $override.addClass('d-none');
        $adviceContainer.removeClass('d-none');
        $adviceContainer.find('.selected-size-value').text(selectedSize);
        $adviceContainer.find('.safesize-value').text(trueSize);
        if (isSmaller) {
            $larger.removeClass('d-none');
            $smaller.addClass('d-none');
            $equal.addClass('d-none');
        } else if (isLarger) {
            $larger.addClass('d-none');
            $smaller.removeClass('d-none');
            $equal.addClass('d-none');
        } else if (isEqual) {
            $larger.addClass('d-none');
            $smaller.addClass('d-none');
            $equal.removeClass('d-none');
        }
    } else {
        $adviceContainer.addClass('d-none');
    }
}

/**
 * Updates the product images
 * @param {Object} response - response from Ajax call
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function updateProductImages(response, $productContainer) {
    // Update primary images
    const primaryImageUrls = response.product.images;
    if (!primaryImageUrls.pdp || !primaryImageUrls.pdp.length) return;
    const $slickSl = $('.slickCarousel .carousel-inner');
    if ($slickSl.length) {
        const $videoItem = $slickSl.find('.video-item');
        const $productSetItem = $slickSl.find('.carousel-item--product-set');
        const newAmt = primaryImageUrls.pdp.length;
        const currentAmount = $('.carousel-item:not(.video-item):not(.carousel-item--product-set)').length;
        if ($productSetItem.length) {
            $productSetItem.remove();
        }
        if (response.product.productSetAssignments.length) {
            response.product.productSetAssignments.forEach((productSetAssignment, i) => {
                let indexToInsert;
                if (newAmt > 1 && i === 0) {
                    indexToInsert = 1;
                } else if (newAmt > 3 && i === 1) {
                    indexToInsert = 4;
                    if (!$videoItem.length && response.product.videoID) {
                        indexToInsert--;
                    }
                } else if (newAmt > 4 && i === 2) {
                    indexToInsert = 7;
                    if (!$videoItem.length && response.product.videoID) {
                        indexToInsert--;
                    }
                }
                if (indexToInsert) {
                    const $insertAfterContainer = $($productContainer.find('.carousel-inner .carousel-item')[indexToInsert]);
                    $(productSetAssignment.html).insertAfter($insertAfterContainer);
                }
            });
        }
        if ($videoItem.length) {
            if (!response.product.videoID) {
                $videoItem.remove();
            }
        } else if (response.product.videoID) {
            let newVideoSlideHTML = '<div class="col-lg-6 carousel-item video-item">';
            newVideoSlideHTML += '<iframe src="" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
            newVideoSlideHTML += '</div>';

            $(newVideoSlideHTML).insertAfter($productContainer.find('.carousel-inner .carousel-item:nth-child(3)'));
        }
        const diff = Math.abs(currentAmount - newAmt);
        if (newAmt > currentAmount) {
            for (let i = 0; i < diff; i++) {
                const newImage = $productContainer.find('.carousel-inner .carousel-item:not(.video-item):not(.carousel-item--product-set)');

                let newImageHTML = '<div class="col-lg-6 carousel-item">';
                newImageHTML += newImage.html();
                newImageHTML += '</div>';

                $productContainer.find('.carousel-inner').append(newImageHTML);
            }
        } else if (newAmt < currentAmount) {
            $productContainer.find('.carousel-inner .carousel-item:not(.video-item):not(.carousel-item--product-set)').slice(newAmt).remove();
        }
    }

    primaryImageUrls.pdp.forEach(function (imageUrl, idx) {
        $productContainer.find('.primary-images').find('carousel-item:not(.carousel-item--product-set) img:not(.discount-label)').eq(idx).attr('src', imageUrl.url);

        var picture = $productContainer.find('.carousel-item:not(.carousel-item--product-set)').find('picture').eq(idx);
        picture.find('source').attr('srcset', imageUrl.url);
        picture.find('img').attr('src', imageUrl.url);
        picture.find('source').attr('data-srcset', `${imageUrl.url} 1x, ${imageUrl.url} 2x, ${imageUrl.url} 3x, `);
        picture.find('img').attr('data-src', imageUrl.url);

        $productContainer.find('.carousel-item:not(.carousel-item--product-set)').find('a').eq(idx).attr('href', imageUrl.url);
    });

    if (response.product.videoID) {
        const $iframe = $slickSl.find('.video-item iframe');
        $iframe.prop('src', `https://player.vimeo.com/video/${response.product.videoID}?title=0&byline=0&portrait=0&autoplay=1&loop=1&autopause=0&background=1&muted=1&controls=0`);
        $iframe.data('video-id', response.product.videoID);
    }

    $productContainer.find('.carousel-item').each((i, el) => {
        const $el = $(el);
        const $a = $el.find('a:first-of-type');
        if ($a.length) {
            $a.data('index', i);
        }
    });

    MagnificPopup.refresh();
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 * @param {Element} target - DOM element for current target
 */
function handleVariantResponse(response, $productContainer, target) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isProductSetDetail = $('.product-set-detail').length > 0;
    var isVariant;

    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    if ($(target).hasClass('swatch-link')) {
        if (!isProductSetDetail) {
            updateProductImages(response, $productContainer);
        } else {
            const $productSetProductImage = $productContainer.find('.set-item__image');
            const $productSetProductPicture = $productSetProductImage.parents('picture');
            if ($productSetProductImage && response.product.images.pdp && response.product.images.pdp.length > 0) {
                const productSetProductImageUrl = response.product.images.pdp[0].url;
                $productSetProductImage.prop('src', productSetProductImageUrl);
                $productSetProductImage.prop('alt', response.product.images.pdp[0].alt);
                $productSetProductImage.prop('data-src', productSetProductImageUrl);
                $productSetProductPicture.find('source').attr('srcset', productSetProductImageUrl);
                $productSetProductPicture.find('source').attr('data-srcset', `${productSetProductImageUrl} 1x, ${productSetProductImageUrl} 2x, ${productSetProductImageUrl} 3x, `);
            }
        }
    }

    const name = response.product.pdpTitle || response.product.productName;
    if (isProductSetDetail) {
        const $productName = $productContainer.find('.product-name a');
        if (name) {
            $productName.text(name);
        }
        const selectedProductUrl = response.product.selectedProductUrl;
        if (selectedProductUrl) {
            $productName.prop('href', selectedProductUrl);
            $productContainer.find('.set-item__image-wrapper').prop('href', selectedProductUrl);
        }
    } else if (name) {
        $productContainer.find('.product-name').text(name);
    }

    if (response.product && response.product.attributesHtml) {
        const $newAttributeHtml = $(response.product.attributesHtml);
        const $currentAttributeHtml = $('#description-and-detail-accordion');
        $currentAttributeHtml.html($newAttributeHtml.html());
    }

    if (!isChoiceOfBonusProducts) {
        const $priceSelector = $('.prices .price:not(.js-saving-price-container)', $productContainer).length ? $('.prices .price:not(.js-saving-price-container)', $productContainer) : $('.prices .price:not(.js-saving-price-container)');
        const $savingPriceContainer = $productContainer.find('.js-saving-price-container');
        $priceSelector.replaceWith(response.product.price.html);
        if ($savingPriceContainer.length && response.product.price.saving) {
            $savingPriceContainer.find('.js-saving-price').text(response.product.price.saving.formatted);
            $savingPriceContainer.removeClass('d-none');
            $savingPriceContainer.addClass('d-flex');
        } else {
            $savingPriceContainer.removeClass('d-flex');
            $savingPriceContainer.addClass('d-none');
        }
    }

    if (response.product.inventory && response.product.inventory.inventoryMsg) {
        $productContainer.find('.product-availability').find('span').empty().append(response.product.inventory.inventoryMsg);
    }

    // Update promotions
    $('.promotions').empty().html(getPromotionsHtml(response.product.promotions));

    updateAvailability(response, $productContainer);

    // Update general size advice
    updateSizeAdvice(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));

    // Update reservation pop-up
    const $image = $('.inventory-check-product__image');
    if ($image && response.product.images.pdp && response.product.images.pdp.length > 0) {
        $image.attr('src', response.product.images.pdp[0].url);
        $image.attr('alt', response.product.images.pdp[0].alt);
    }
    $('.inventory-check-product__details--name').text(response.product.pdpTitle || response.product.productName);
    let selectedSize = null;
    response.product.variationAttributes[1].values.forEach(size => {
        if (size.selected) {
            selectedSize = size.displayValue;
        }
    });
    $('.inventory-check-product__details--size').text(`${response.product.variationAttributes[1].displayName}: ${selectedSize}`);
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"'
                + selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * Updates the discount labels
 * @param {Object} product - the product of which the labels need to be shown
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateDiscountLabels(product, $productContainer) {
    if (product && !product.isOutOfStock) {
        const primaryImagesSelector = $productContainer.find('.primary-images, .js-productAttributes');

        if (product.isWebExclusive === true) {
            primaryImagesSelector.find('.discount-label--web').parent().removeClass('d-none');
        } else {
            primaryImagesSelector.find('.discount-label--web').parent().addClass('d-none');
        }

        if (product.showPromoLabel && product.promoLabel && product.promoLabel !== '') {
            primaryImagesSelector.find('.discount-label--promo').parent().removeClass('d-none');
            primaryImagesSelector.find('.discount-label--promo span').text(product.promoLabel);
        } else {
            primaryImagesSelector.find('.discount-label--promo').parent().addClass('d-none');
        }

        if (!product.showPromoLabel && product.discountPercentage && product.discountPercentage !== '') {
            primaryImagesSelector.find('.discount-label--percentage').parent().removeClass('d-none');
            primaryImagesSelector.find('.discount-label--percentage span').text(`-${product.discountPercentage}%`);

            let labelColor;
            if (product.price.isConditionalPrice) {
                if (product.price.conditionalPriceColor) {
                    labelColor = product.price.conditionalPriceColor;
                }
            } else {
                labelColor = product.discountLabelColor;
            }

            if (labelColor) {
                primaryImagesSelector.find('.discount-label--percentage').css({
                    background: labelColor,
                    'border-color': labelColor
                });
            }
        } else {
            primaryImagesSelector.find('.discount-label--percentage').parent().addClass('d-none');
        }

        primaryImagesSelector.find('.discount-label--new').parent().toggleClass('d-none', !product.isNew);

        primaryImagesSelector.find('.discount-label--cool-price').parent().toggleClass('d-none', !product.isCoolPrice);

        primaryImagesSelector.find('.discount-label--topper').parent().toggleClass('d-none', !product.torfsToppersLabel || product.torfsToppersLabel === '');

        primaryImagesSelector.find('.discount-label--eco').parent().toggleClass('d-none', !product.isVegan);

        if (product.torfsToppersLabel && product.torfsToppersLabel !== '') {
            primaryImagesSelector.find('.discount-label--topper span').text(product.torfsToppersLabel);
            primaryImagesSelector.find('.discount-label--topper').parent().removeClass('d-none');
        } else {
            primaryImagesSelector.find('.discount-label--topper').parent().addClass('d-none');
        }
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {Element} target - DOM element for current target
 * @param {jQuery} follow - track gddl or not
 */
function attributeSelect(selectedValueUrl, $productContainer, target, follow) {
    if (selectedValueUrl) {
        $('body').trigger(
            'product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer, target: target }
        );

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer, target);
                updateOptions(data.product.options, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                updateDiscountLabels(data.product, $productContainer);
                $('body').trigger(
                    'product:afterAttributeSelect',
                    {
                        data: data, container: $productContainer, target: target, gddl: follow !== 'nogddl'
                    }
                );
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieves url to use when adding a product to the cart. This request will render
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartModalUrl() {
    return $('.add-to-cart-modal-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $.spinner().start();
    const $modal = $('#chooseBonusProductModal');
    const $dialog = $modal.find('.choose-bonus-product-dialog');

    $dialog.attr('data-total-qty', data.maxBonusItems);
    $dialog.attr('data-UUID', data.uuid);
    $dialog.attr('data-pliUUID', data.pliUUID);
    $dialog.attr('data-addToCartUrl', data.addToCartUrl);
    $dialog.attr('data-pageStart', 0);
    $dialog.attr('data-pageSize', data.pageSize);
    $dialog.attr('data-moreURL', data.showProductsUrlRuleBased);
    $dialog.attr('data-bonusChoiceRuleBased', data.bonusChoiceRuleBased);

    const url = data.bonusChoiceRuleBased ? data.showProductsUrlRuleBased : data.showProductsUrlListBased; // Product-ShowBonusProducts

    $.ajax({
        url: url,
        method: 'GET',
        dataType: 'html',
        success: function (html) {
            var parsedHtml = parseHtml(html);
            $modal.find('.modal-body').html(parsedHtml.body);
            $modal.find('.modal-footer').html(parsedHtml.footer);

            $modal.modal('show');
            select.init();
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

module.exports = {
    getAddToCartUrl: getAddToCartUrl,
    getAddToCartModalUrl: getAddToCartModalUrl,
    getChildProducts: getChildProducts,
    getQuantitySelected: getQuantitySelected,
    getOptions: getOptions,
    attributeSelect: attributeSelect,
    handlePostCartAdd: handlePostCartAdd,
    updateDiscountLabels: updateDiscountLabels,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },
    colorAttribute: function () {
        $(document).on('click', '[data-attr] a', function (e, follow) {
            e.preventDefault();
            const $this = $(this);
            if (($('.product-detail--outofstock').length || $this.parents('.product-info--out-of-stock').length) && !$this.attr('disabled') && $this.attr('href')) {
                window.location = '' + $this.attr('href');
            }

            if ($this.attr('disabled') || $this.hasClass('disabled')) return;

            const $colorValue = $this.find('.swatch-circle');
            if ($colorValue.length && $colorValue.hasClass('selected')) return;

            var $productContainer = $this.closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $this.closest('.product-detail');
            }

            attributeSelect(e.currentTarget.href, $productContainer, e.currentTarget, follow);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e, follow) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer, e.currentTarget, follow);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e, follow) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect(
                    $(e.currentTarget).find('option:selected').data('url'),
                    $productContainer,
                    e.currentTarget,
                    follow
                );
            }
        });
    },
    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var $quantityLabel = $choiceOfBonusProduct.find('.bonus-quantity .attribute-name');
            var $quantityErrorMessage = $choiceOfBonusProduct.find('.bonus-quantity-error');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($(this).parents('.choice-of-bonus-product').find('.bonus-quantity .input-quantity').val(), 10);
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $(this).parents('.choice-of-bonus-product').find('.product-option').data('option-id');
            var valueId = $(this).parents('.choice-of-bonus-product').find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ''
                    + '<div class="selected-pid" '
                    + 'data-pid="' + pid + '"'
                    + 'data-qty="' + submittedQty + '"'
                    + 'data-optionID="' + (optionID || '') + '"'
                    + 'data-option-selected-value="' + (valueId || '') + '"'
                    + '>'
                    + '<div class="bonus-product-remove-icon">'
                    + '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3B3F3A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>'
                    + '</div>'
                    + '<div class="bonus-product-name" >'
                    + '' + submittedQty + ' x ' + $choiceOfBonusProduct.find('.product-name').html()
                    + '</div>'
                    + '</div>';

                $('#chooseBonusProductModal .selected-bonus-products .bonus-summary__selected-products-list').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $quantityLabel.removeClass('error');
                $quantityErrorMessage.addClass('d-none');
            } else {
                $quantityLabel.addClass('error');
                $quantityErrorMessage.removeClass('d-none');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.choice-of-bonus-product .bonus-quantity .attribute-name').removeClass('error');
            $('.choice-of-bonus-product .bonus-quantity-error').addClass('d-none');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr(
                'disabled',
                (!response.product.readyToOrder || !response.product.available)
            );
            var pid = response.product.id;
            $('button.select-bonus-product').data('pid', pid);
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption = parseInt($(this)
                    .data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('.error-choice-of-bonus-products')
                            .html(data.errorMessage);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');
                        window.location.reload();
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    getPidValue: getPidValue
};
