'use strict';

const getValues = (inputField) => {
    var currentValue = parseInt(inputField.val(), 10);
    var minValue = parseInt(inputField.attr('min'), 10);
    var maxValue = parseInt(inputField.attr('max'), 10);

    return { currentValue, minValue, maxValue };
};

const increaseQuantity = () => {
    $('body').on('click', '.increase-quantity', function () {
        const inputField = $(this).siblings('.input-quantity');
        let { currentValue, maxValue, minValue } = getValues(inputField);

        if (currentValue < maxValue) {
            currentValue++;
            inputField.val(currentValue);

            if (inputField.parents('.quantity-form').length) {
                inputField.trigger('change');
            }

            if (currentValue === maxValue) {
                $(this).addClass('disabled');
            }
        }

        if (currentValue > minValue) {
            $(this).siblings('.decrease-quantity').removeClass('disabled');
        }
    });
};

const decreaseQuantity = () => {
    $('body').on('click', '.decrease-quantity', function () {
        const inputField = $(this).siblings('.input-quantity');
        let { currentValue, maxValue, minValue } = getValues(inputField);

        if (currentValue > minValue) {
            currentValue--;
            inputField.val(currentValue);

            if (inputField.parents('.quantity-form').length) {
                inputField.trigger('change');
            }

            if (currentValue === minValue) {
                $(this).addClass('disabled');
            }
        }

        if (currentValue < maxValue) {
            $(this).siblings('.increase-quantity').removeClass('disabled');
        }
    });
};

const init = () => {
    increaseQuantity();
    decreaseQuantity();
};

module.exports = {
    init: init
};
