'use strict';

/**
 * Updates and refactors the callout messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 * @param {Object} items - items inside the cart
 */
function updateCalloutMessages(approachingDiscounts, items) {
    $('.cart__callouts div').remove();
    var messages = [];

    if (approachingDiscounts && approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (discount) {
            var message = `<div class="single-approaching-discount">${discount.discountMsg}</div>`;
            if (!messages.includes(message)) {
                messages.push(message);
            }
        });
    }
    if (items && items.length > 0) {
        items.forEach(function (item) {
            if (item.appliedPromotions && item.appliedPromotions.length > 0) {
                item.appliedPromotions.forEach(function (promotion) {
                    $.ajax({
                        url: $('.js-linkToAsset').data('url') + `?contentAssetID=${promotion.contentAssetID}`,
                        method: 'GET',
                        dataType: 'html',
                        success: function (data) {
                            var temp = JSON.parse(data);
                            var message = $.parseHTML(temp.html);
                            if (message && message !== '' && message.length > 0 && !messages.includes(message[0].innerHTML)) {
                                messages.push(message[0].innerHTML);
                                var $div = $('<div class="single-applied-promotion"></div>');
                                $div.append(message);
                                $('.cart__callouts').append($div);
                            }
                        }
                    });
                });
            }
        });
    }
}
/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    if (data.totals.totalShippingCost !== '-' && data.totals.shippingValue.value !== 0) {
        $('.shipping-total-cost-label').removeClass('shipping-total-cost-label--free');
        $('.shipping-cost').removeClass('totals-price-free');
        $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    } else {
        $('.shipping-total-cost-label').addClass('shipping-total-cost-label--free');
        $('.shipping-cost').addClass('totals-price-free');
        $('.shipping-cost').empty().append($('.shipping-cost').data('free'));
    }
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.total-gross-price').empty().append(data.totals.merchandizeTotalGrossPrice);
    $('.minicart-quantity .counter').empty().append(data.numItems);

    if (data.savings !== 0) {
        $('.order-discount').removeClass('d-none');
        $('.order-discount-total').empty()
            .append('- € ' + data.savings.toFixed(2).toString().replace('.', ','));
    } else {
        $('.order-discount').addClass('d-none');
        $('.order-discount-total').empty();
    }

    $('.order-price-without-discount').toggleClass('d-none', data.savings === 0 && data.bmCouponsTotal === 0);

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- '
            + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        const name = item.pdpTitle || item.productName;
        if (name) {
            $('.item-title-' + item.UUID).find('a').empty().append(name);
        }
        if (item.id) {
            $('.item-id-' + item.UUID).find('span').empty().append(item.colorVariantID);
        }
        if (item.inventory.deliveryType !== '') {
            $('.delivery-msg-' + item.UUID)
                .not('.text-danger')
                .find('span')
                .empty()
                .append(data.inventoryMsg);
        }
    });

    if ($('.cart__loyalty').length) {
        $.ajax({
            url: $('.cart__loyalty').data('url'),
            method: 'GET',
            dataType: 'html',
            success: function (html) {
                $('.cart__loyalty').replaceWith(html);
            },
            error: function () {
            }
        });
    }
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
                + 'fade show" role="alert">'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                + '<span aria-hidden="true">&times;</span>'
                + '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart .cart-page').empty();
            $('.cart .cart-empty').removeClass('d-none');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').data('item-count', data.numItems);
            $('.minicart-quantity .counter').empty().append(data.numItems);
            $('.minicart .popover').removeClass('show');
            setTimeout(function () {
                $('.minicart .popover').empty();
                $('.minicart .popover').hide();
            }, 200);
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * Update product label for the cart product card
 * @param {Object} data - response from api call
 */
function updateCartProductCardLabel(data) {
    $('.card.product-info').each(function (i, productCard) {
        const $productCard = $(productCard);
        const pCardUuid = $productCard.attr('data-uuid');

        data.items.forEach(function (lineItem) {
            if (lineItem.UUID === pCardUuid) {
                if (lineItem.showPromoLabel && lineItem.promoLabel.length) {
                    $productCard.find('.js-discount-percentage-container').addClass('d-none');
                    $productCard.find('.js-discount-label-content').text(lineItem.promoLabel);
                    $productCard.find('.js-discount-label-container').removeClass('d-none');
                } else if (lineItem.discountPercentage) {
                    $productCard.find('.js-discount-label-container').addClass('d-none');
                    $productCard.find('.js-discount-percentage-content').text('-' + lineItem.discountPercentage + '%');
                    $productCard.find('.js-discount-percentage-container').removeClass('d-none');
                } else {
                    $productCard.find('.js-discount-label-container').addClass('d-none');
                    $productCard.find('.js-discount-percentage-container').addClass('d-none');
                }
            }
        });
    });
}

/**
 * This will reload the data dynamically
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid -unique id of the prod
 */
function redrawCartItems(data, uuid) {
    if (data.shouldReload) location.reload();
    if (data.basket.items.length === 0) {
        $('.cart .cart__header__label').empty();
        $('.cart .cart-page').empty();
        $('.cart .cart-empty').removeClass('d-none');
        $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
        $('.minicart-quantity').data('item-count', data.basket.numItems);
        $('.minicart-quantity .counter').empty().append(data.basket.numItems);
        $('.minicart .popover').removeClass('show');
        setTimeout(function () {
            $('.minicart .popover').empty();
            $('.minicart .popover').hide();
        }, 200);
        $('body').removeClass('modal-open');
        $('html').removeClass('veiled');
    } else {
        $('.cart .cart-empty').addClass('d-none');
        if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
            for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
            }
        }
        $('.uuid-' + uuid).remove();
        if (!data.basket.hasBonusProduct) {
            $('.bonus-product').remove();
        }
        $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
        $('.bm-coupons').empty().append(data.basket.totals.BMCouponsHtml);
        updateCartProductCardLabel(data.basket);
        updateCartTotals(data.basket);
        updateCalloutMessages(data.basket.approachingDiscounts, data.basket.items);
        $('.cart-eco').toggleClass('d-none', !(data.basket.items.length > 1));
        $('body').trigger('setShippingMethodSelection', data.basket);
        validateBasket(data.basket);
        $('.checkout-continue .delivery-msg .delivery-msg-txt').html(data.basket.inventoryMsg);
    }
}

module.exports = {
    updateCartTotals: updateCartTotals,
    updateCalloutMessages: updateCalloutMessages,
    validateBasket: validateBasket,
    redrawCartItems: redrawCartItems,
    updateCartProductCardLabel: updateCartProductCardLabel
};
