'use strict';

const MagnificPopup = {};

MagnificPopup.init = () => {
    const $carouselItems = $('.carousel-inner .carousel-item a:first-of-type');
    if (!$carouselItems.length) return;

    MagnificPopup.initCarouselItems($carouselItems);
    MagnificPopup.initOnClickHandler($carouselItems);
};

MagnificPopup.initCarouselItems = ($carouselItems) => {
    const items = MagnificPopup.getItems($carouselItems);
    const closeLabel = $('.primary-images').data('close-mfp-label');
    const ofLabel = $('.primary-images').data('mfp-counter-of-label');

    $('body').on('click', '.mfp-custom-image-product-set-tile .carousel-item__product-set-tile', (e) => {
        e.preventDefault();
    });

    $carouselItems.magnificPopup({
        callbacks: {
            open: function () {
                $('body').addClass('no-scroll');
                let thumbnailsHtml = '<div class="mfp-custom-thumbnails">';
                items.forEach((item, index) => {
                    if (item.type && item.type === 'iframe' && item.videoID) {
                        thumbnailsHtml += `<div class="mfp-custom-thumbnails__thumbnail mfp-custom-thumbnails__thumbnail--iframe" data-index="${index}" data-video-id="${item.videoID}"><img src=""></div>`;
                    } else {
                        thumbnailsHtml += `<div class="mfp-custom-thumbnails__thumbnail" data-index="${index}"><img src="${item.thumbnailSrc ? item.thumbnailSrc : item.src}"></div>`;
                    }
                });
                thumbnailsHtml += '</div>';
                const $mfp = $('.mfp-wrap.mfp-gallery');
                $mfp.append(thumbnailsHtml);
                $mfp.append(`<div class="mfp-custom-counter"><span><span class="mfp-custom-counter-index">${this.index + 1}</span> ${ofLabel} <span class="custom-counter-length">${this.items.length}</span></span></div>`);
                const $iframeThumbnails = $mfp.find('.mfp-custom-thumbnails__thumbnail--iframe');
                if ($iframeThumbnails.length) {
                    $iframeThumbnails.each((i, el) => {
                        const $el = $(el);
                        const videoID = $el.data('video-id');
                        $.get(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${videoID}`, (data) => {
                            $el.find('img').prop('src', data.thumbnail_url_with_play_button);
                        });
                    });
                }
                $('.mfp-custom-thumbnails__thumbnail').click(e => {
                    this.goTo($(e.currentTarget).data('index'));
                });

                var self = this;
                self.wrap.on('click.pinhandler', 'img', function () {
                    self.wrap.toggleClass('mfp-force-scrollbars');
                });
            },
            beforeClose: function () {
                this.wrap.off('click.pinhandler');
                this.wrap.removeClass('mfp-force-scrollbars');
            },
            change: function () {
                const mfpThumbnailSelectedClass = 'mfp-custom-thumbnails__thumbnail--selected';
                $(`.${mfpThumbnailSelectedClass}`).removeClass(mfpThumbnailSelectedClass);
                $('.mfp-custom-counter-index').text(this.index + 1);
                $(`.mfp-custom-thumbnails__thumbnail[data-index="${this.index}"]`).addClass(mfpThumbnailSelectedClass);
            },
            close: function () {
                $('body').removeClass('no-scroll');
                $('.mfp-custom-counter').remove();
                $('.mfp-custom-thumbnails').remove();
            }
        },
        closeMarkup: `<button title="${closeLabel}" type="button" class="mfp-close"><span><span>${closeLabel}</span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span></button>`,
        closeOnBgClick: false,
        gallery: {
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow--mobile mfp-arrow-%dir% btn btn-primary d-lg-none"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg></button>'
                            + '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir% d-none d-lg-block"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></button>',
            enabled: true,
            preload: [1, 3],
            navigateByImgClick: false
        },
        items: items,
        showCloseBtn: true,
        type: 'image',
        image: {
            verticalFit: false
        }
    });
};

MagnificPopup.refresh = () => {
    const $carouselItems = $('.carousel-inner .carousel-item a:first-of-type');
    if (!$carouselItems.length) return;

    MagnificPopup.destroyOnClickHandler($carouselItems);
    MagnificPopup.initCarouselItems($carouselItems);
    MagnificPopup.initOnClickHandler($carouselItems);
};

MagnificPopup.getItems = ($carouselItems) => {
    // find all images
    const productImgArray = $carouselItems.toArray();
    // foreach element make it a json file like this: {src: img.src} default will be image
    const items = productImgArray.map(aLink => {
        const $aLink = $(aLink);
        if ($aLink.hasClass('carousel-item__product-set-tile')) {
            const $productSetTile = $aLink.parents('.carousel-item--product-set').clone();
            $productSetTile.find('img').addClass('mfp-img');
            const html = '<div class="mfp-custom-image-product-set-tile">' + $productSetTile.html() + '</div>';
            return {
                src: html,
                type: 'inline',
                thumbnailSrc: $aLink.data('image-url')
            };
        }
        return { src: aLink.getAttribute('href') };
    });
    // now add the video link if there is one.
    const $iframe = $('.video-item iframe');

    if ($iframe.length) {
        const videoLink = $iframe.prop('src');
        const videoID = $iframe.data('video-id');
        items.splice(3, 0, { src: videoLink, type: 'iframe', videoID: videoID });
    }
    return items;
};

MagnificPopup.initOnClickHandler = ($carouselItems) => {
    $carouselItems.on('click', (e) => {
        e.preventDefault();
        const index = $(e.currentTarget).data('index');
        $.magnificPopup.instance.goTo(index);
    });
};

MagnificPopup.destroyOnClickHandler = ($carouselItems) => {
    $carouselItems.off('click');
};

module.exports = MagnificPopup;
