'use strict';

const processInclude = require('base/util');

$(() => {
    processInclude(require('./cart/cart'));
    processInclude(require('./components/dropdown'));
    require('./analytics/checkoutAnalytics').initCart();
});

require('./thirdParty/magnific');
